.cart-collapse.ant-collapse-borderless {
    background-color: transparent !important;
    .ant-collapse-item.cart-collapse-panel {
        border: none;
    }
    > .ant-collapse-item.cart-collapse-panel {
        > div.ant-collapse-header {
            background-color: rgba(0, 0, 0, 0.05);
        }
        .ant-collapse-content {
            background: transparent;
        }
    }
    .ant-collapse-content-box {
        padding: 0 !important;
    }
    .ant-collapse-arrow {
        top: 9px !important;
    }
}

.ant-btn.discount-btn {
    width: auto;
    height: 22px;
    padding: 0;
    margin: 0;
    color: #000;
    border: none;
    border-bottom: 1px dotted #000;
    line-height: 1;
    &:hover,
    &:focus {
        color: #1891ff;
        border-bottom: 1px dotted #1891ff;
    }
    span {
        width: 100%;
    }
}

.discount-input-wrapper {
    display: flex;
    justify-content: right;
    height: 22px;
    width: 100% !important;
    .ant-input {
        height: 22px;
        width: 60px !important;
        font-size: 12px;
        // padding: 0;
    }
    .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        width: 22px;
        height: 22px;
    }
}

.order-checkout-table-summary {
    .ant-table {
        background: none;
        tr {
            background: none;
            th {
                font-weight: bold;
                text-align: right;
                padding: 8px 4px 10px 4px;
                &::before {
                    display: none;
                }
            }
            td {
                background: none;
                border: none;
                padding: 4px;
                &:first-child {
                    color: rgba(0, 0, 0, 0.45);
                }
                &:not(:first-child) {
                    font-weight: bold;
                    text-align: right;
                }
            }
        }
    }
}