table.slot-table {
  thead {
    tr {
      th {
        padding: 10px 20px;
        width: 10%;
      }
    }
  }
  tbody {
    tr {
      th {
        padding: 10px 20px;
      }
      td {
        text-align: center;
        cursor: pointer;
        &.empty {
          background-color: #f8f8f8;
        }
        .cell-value {
          display: flex;
          > div {
            flex-direction: column;
            &.cell-value-prefix {
              background-color: #aaaaaa;
              padding: 10px 5px;
            }

            &.cell-value-basic {
              padding: 10px 5px;
              white-space: nowrap;
              width: 100%;
            }
          }
        }
      }
    }
  }
}