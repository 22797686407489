.ant-checkbox-wrapper.orders-apply-checkbox {
  display: flex;
}

.orders-apply-checkbox {
  width: 52px;
  justify-content: center;
  height: 52px;
  align-items: center;
  border-radius: 50%;
}
