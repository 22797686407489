.not-found {
  height: calc(100vh - 90px);
  justify-content: center;
  &_title {
    font-size: 7rem;
    margin: 0;
  }
  &_text {
    font-size: 3rem;
  }
  &_link {
    font-size: 2rem;
  }
}
