.filter-wrapper {
    display: flex;
    align-items: baseline;
    position: relative;
    //width: 100%;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 6px 5px 0px 5px;
    //margin-bottom: 10px;
    cursor: pointer;

    > .ant-tag {
        vertical-align: 1px !important;
        margin-bottom: 6px;
    }
    .filter-icon {
        display: none;
    }
}
.tags-wrapper {
    display: flex;
}

.filterPlaceHolder {
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 6px;
    display: inline-block;
}

.filterAddFilterButton {
    padding: 0px 10px !important;
    width: auto !important;
    height: auto !important;
    //vertical-align: middle;
    opacity: 0.7;
    line-height: 16px !important;
    vertical-align: -1px !important;
    margin-bottom: 7px;

    &:hover {
        background-color: rgba(0, 0, 0, 0) !important;
        opacity: 1;
    }
}

@media screen and (min-width: 450px) {
    .filter-wrapper {
        > .filter-icon {
            display: inline-block;
        }
    }
}
