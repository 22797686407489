$queue-job-color-awaiting: #fffc3e;
$queue-job-color-running: #d5ff3e;
$queue-job-color-completed: #3eff6b;
$queue-job-color-failed: #ff3e3e;
$queue-job-color-cancelled: #eaeaea;

.queue-job {

  &.queue-job-status-awaiting {
    border-left: 4px solid $queue-job-color-awaiting;
    .ant-tag {
      background-color: $queue-job-color-awaiting;
      color: #000000;
    }
  }

  &.queue-job-status-running {
    border-left: 4px solid $queue-job-color-running;
    .ant-tag {
      background-color: $queue-job-color-running;
      color: #000000;
    }
  }

  &.queue-job-status-completed {
    border-left: 4px solid $queue-job-color-completed;
    .ant-tag {
      background-color: $queue-job-color-completed;
      color: #000000;
    }
  }

  &.queue-job-status-failed {
    border-left: 4px solid $queue-job-color-failed;
    .ant-tag {
      background-color: $queue-job-color-failed;
    }
  }

  &.queue-job-status-cancelled {
    border-left: 4px solid $queue-job-color-cancelled;
    .ant-tag {
      background-color: $queue-job-color-cancelled;
      color: #000000;
    }
  }

  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 20px 0;
  .queue-job-title {
    justify-content: space-between;
    display: flex;
    .queue-job-title-class {
      font-weight: bold;
    }
  }
  .queue-job-message {
    .queue-job-message-last-error {
      background-color: #ff7070;
      border-radius: 5px;
      color: #ffffff;
      padding: 10px;
      margin: 10px 0 0 0;
      word-break: break-all;
    }

    .queue-job-message-date {
      font-style: italic;
    }

    .queue-job-message-payload {
      table {
        margin-top: 10px;

        tr {
          td, th {
            border: 1px solid #d7d7d7;
            padding: 1px 10px;

            &:first-child {
              background-color: #e6e6e6;
            }
          }
        }
      }
    }
  }

  .queue-job-actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }

  .ant-tag {
    color: #ffffff;
    opacity: 0.8;
    border: none;
    margin: 0;
  }
}