.article {
  .ant-collapse.article_collapse-inner {
    background-color: transparent;

    .ant-collapse.article_collapse-inner {
      >.ant-collapse-item {
        border: none;
        >.ant-collapse-header {
          border-bottom: 1px solid #D9D9D9;
        }
      }
    }
  }
  .ant-collapse.article_collapse-model {
    >.ant-collapse-item {
      >.ant-collapse-header {
        font-weight: bold;
        color: #7F7F7F;
      }
    }
  }
}
