.cart-row-backorder {
  color: #f93822;
  .cart-row-backorder-mark {
    font-size: 10px;
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.disabled {
  color: #bdbdbd;
}

.ant-row.orders-create-buttons-legend {
  flex-direction: column-reverse;
}
.ant-row.orders-create-buttons {
  margin-bottom: 20px;
  justify-content: start;
  button {
    margin-right: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 1200px){

  .ant-row.orders-create-buttons-legend {
    flex-direction: row;
  }

  .ant-row.orders-create-buttons {
    margin-bottom: 0;
    justify-content: flex-end;

    button {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}
