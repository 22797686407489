.error {
  &-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-title {

  }
  &-link {
    font-size: 1.1rem;
  }
}
