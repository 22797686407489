.nav-logout-button {

  button.ant-btn {
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
  }

  &:focus-within {
    background-color: #f93822;
  }

}

 .nav-item {
   position: relative;
   .nav-attentions {
     position: absolute;
     right: 6px;
     bottom: 3px;
     border-radius: 6px;
     padding: 1px 4px 1px 4px;
     font-size: 11px;
     text-align: center;
     &.nav-attentions-attention {
       background-color: #f93822;
     }
     &.nav-attentions-info {
       background-color: #f9d522;
       color: #1b1b1b;
     }
   }
   &:hover {
     .nav-attentions {
       right: calc(100% - 44px);
     }
   }
 }

 .rolled-up-menu, .full-screen-menu {
   .nav-item {
     .nav-attentions {
       bottom: 16px;
       right: 15px;
     }
   }
 }