.price-input {
  width: 100% !important;
  input[type="number"] {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
  }
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}
