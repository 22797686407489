$blue-hover: #4089b7;
$red-hover: #f93822;
$nav-background: #282c34;

$menu-rolled-down-width: 50px;
$menu-rolled-up-width: 260px;
$menu-collapsed-width: 100%;
$menu-full-screen-width: 100%;

$menu-rolled-down-height: 100%;
$menu-rolled-up-height: 100%;
$menu-collapsed-height: 50px;
$menu-full-screen-height: 100%;

$nav-item-rolled-down-width: 50px;
$nav-item-rolled-up-width: 260px;
$nav-item-collapsed-width: 50px;
$nav-item-full-screen-width: 290px;

$nav-item-height: 50px;

$nav-img-container-width: 50px;
$nav-img-container-height: 50px;

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: sans-serif;
}

html,
body {
    width: 100%;
    height: auto;
    min-height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 14px;
}

main {
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}

div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

#root {
    background-color: #f2f2f2;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    background-color: $nav-background;

    div {
        display: flex;
    }

    div.nav-item {
        > a,
        button {
            display: flex;
            cursor: pointer;
            text-decoration: none;
            background-color: transparent;
            color: #fff;
            margin: 0;
            padding: 0;
            outline: none;

            &.red {
                &-active,
                &:hover {
                    background-color: $red-hover;
                }
            }

            &.blue {
                &-active,
                &:hover {
                    background-color: $blue-hover;
                }
            }
        }
    }

    .nav-img-container {
        width: $nav-img-container-width;
        height: $nav-img-container-height;

        > img {
            width: 26px;
            height: auto;
            margin: auto 12px;
        }

        > i {
            display: inline-flex;
            font-size: 15px;

            &:before {
                display: inline-flex;
                margin: auto;
            }
        }

        > svg {
            display: inline-flex;
            margin: auto;
        }

        > .nav-initials {
            font-weight: bold;
            font-size: 18px;
        }
    }

    > .nav-main-container {
        width: 100%;
        height: 100%;
        flex-direction: column;

        > div.nav-item {
            display: flex;
            height: $nav-item-height;

            > a {
                height: $nav-item-height;
            }

            > button {
                height: $nav-item-height;
            }

            &.nav-roll-up-menu-button,
            &.nav-open-close-menu-button {
                display: none;
            }
            &.nav-logout-button {
                display: flex;

                span {
                    display: none;
                }

                button {
                    border: none;
                }
            }

            &.nav-open-close-menu-button {
                position: fixed;
                top: 0;
                right: 0;
                background-color: $nav-background;

                > button {
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    border: 0px;
                }
            }
        }

        > div.nav-items-container {
            > div.nav-item {
                height: $nav-item-height;

                > a {
                    height: $nav-item-height;

                    > span {
                        display: none;
                    }
                }
            }
        }
    }

    &.rolled-down-menu {
        width: $menu-rolled-down-width;
        height: $menu-rolled-down-height;
        flex-direction: column;

        > .nav-main-container {
            > div.nav-item {
                width: $nav-item-rolled-down-width;

                > a {
                    width: $nav-item-rolled-down-width;

                    &:hover {
                        //position: absolute;
                        flex-direction: row;
                        width: auto;

                        > span {
                            display: flex;
                            padding-right: 15px;
                            align-items: center;
                        }
                    }
                }

                > button {
                    width: $nav-item-rolled-down-width;
                }

                &.nav-logout-button {
                    display: flex;
                    margin-top: auto;

                    span {
                        display: none;
                    }
                }

                &.nav-roll-up-menu-button {
                    display: flex;

                    > button {
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        border: 0px;
                    }
                }
            }

            > div.nav-items-container {
                width: $nav-item-rolled-down-width;
                height: auto;
                flex-direction: column;

                > div.nav-item {
                    width: $nav-item-rolled-down-width;

                    > a {
                        width: $nav-item-rolled-down-width;
                        flex-direction: column;

                        &:hover {
                            position: absolute;
                            flex-direction: row;
                            width: auto;
                            white-space: nowrap;

                            > span {
                                display: flex;
                                padding-right: 15px;
                                align-items: center;
                            }
                        }
                    }

                    > a.active {
                        background-color: $blue-hover;
                    }
                }
            }
        }
    }

    &.rolled-up-menu {
        width: $menu-rolled-up-width;
        height: $menu-rolled-up-height;

        > .nav-main-container {
            > div.nav-item {
                width: $nav-item-rolled-up-width;

                > a {
                    width: $nav-item-rolled-up-width;
                    flex-direction: row;

                    > span {
                        display: flex;
                        align-items: center;
                    }
                }

                > button {
                    width: $nav-item-rolled-up-width;
                }

                &.nav-logout-button {
                    display: flex;
                    margin-top: auto;
                }

                &.nav-roll-up-menu-button {
                    display: flex;

                    > button {
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        border: 0px;
                    }
                }
            }

            > div.nav-items-container {
                width: $nav-item-rolled-up-width;
                height: auto;
                flex-direction: column;

                > div.nav-item {
                    width: $nav-item-rolled-up-width;

                    > a {
                        width: $nav-item-rolled-up-width;
                        flex-direction: row;

                        > span {
                            display: flex;
                            align-items: center;
                        }
                    }
                    > a.active {
                        background-color: $blue-hover;
                    }
                }
            }
        }
    }

    &.collapsed-menu {
        flex-direction: row;
        position: fixed;
        top: 0;
        width: $menu-collapsed-width;
        height: $menu-collapsed-height;

        > .nav-main-container {
            flex-direction: row;

            > div.nav-item {
                width: $nav-item-collapsed-width;

                > a {
                    width: $nav-item-collapsed-width;
                }

                > button {
                    width: $nav-item-collapsed-width;
                }

                &.nav-open-close-menu-button {
                    display: flex;
                }
            }

            > div.nav-items-container {
                width: auto;
                height: $nav-item-height;
                flex-direction: row;
                margin: 0 auto;

                > div.nav-item {
                    width: $nav-item-collapsed-width;

                    > a {
                        width: $nav-item-collapsed-width;
                        flex-direction: column;

                        &:hover {
                            flex-direction: column;
                            width: $nav-item-collapsed-width;
                        }
                    }
                    > a.active {
                        background-color: $blue-hover;
                    }
                }
            }
        }
    }

    &.full-screen-menu {
        width: $menu-full-screen-width;
        height: $menu-full-screen-height;
        align-items: center;
        overflow-y: auto;

        > .nav-main-container {
            width: auto;
            height: auto;
            margin: auto;

            > div.nav-item {
                width: $nav-item-full-screen-width;
                margin: 0 auto;

                > a {
                    width: $nav-item-full-screen-width;
                    flex-direction: row;

                    > span {
                        display: flex;
                        align-items: center;
                    }
                }

                > button {
                    width: $nav-item-full-screen-width;
                }

                &.nav-logout-button {
                    display: flex;
                }

                &.nav-open-close-menu-button {
                    display: flex;
                    width: $nav-item-collapsed-width;
                }
            }

            > div.nav-items-container {
                width: $nav-item-full-screen-width;
                height: auto;
                flex-direction: column;
                margin: 0 auto;

                > div.nav-item {
                    width: $nav-item-full-screen-width;

                    > a {
                        width: $nav-item-full-screen-width;
                        flex-direction: row;
                        //background-color: red;

                        > span {
                            display: flex;
                            align-items: center;
                        }
                    }
                    > a.active {
                        background-color: $blue-hover;
                    }
                }
            }
        }
    }

    &.nav-animation-from-rolled-down-to-rolled-up {
        animation: key-nav-animation-from-rolled-down-to-rolled-up 0.3s ease;

        @keyframes key-nav-animation-from-rolled-down-to-rolled-up {
            0% {
                width: 50px;
            }
            100% {
                width: 290px;
            }
        }
    }

    &.nav-animation-from-rolled-up-to-rolled-down {
        animation: key-nav-animation-from-rolled-up-to-rolled-down 0.3s ease;

        @keyframes key-nav-animation-from-rolled-up-to-rolled-down {
            0% {
                width: 290px;
            }
            100% {
                width: 50px;
            }
        }
    }

    &.nav-animation-from-collapsed-to-full-screen {
        animation: key-nav-animation-from-collapsed-to-full-screen 0.5s ease;

        @keyframes key-nav-animation-from-collapsed-to-full-screen {
            0% {
                height: $nav-item-height;
            }
            100% {
                height: 100%;
            }
        }
    }

    &.nav-animation-from-full-screen-to-collapsed {
        animation: key-nav-animation-from-full-screen-to-collapsed 0.5s ease;

        @keyframes key-nav-animation-from-full-screen-to-collapsed {
            0% {
                height: 100%;
            }
            100% {
                height: $nav-item-height;
            }
        }
    }
}

.rs-container {
    flex-direction: column;
    display: inline-flex;
    flex: auto;
    //max-width: 1450px;
    background: #fff;
    box-shadow: 24px 0 30px -4px rgba(34, 60, 80, 0.1);

    > .main-container {
        padding: 20px 55px;
        min-height: 800px;

        &-login {
            background-image: url("../security/assets/bg.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    > .section-top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        background-color: #fafafa;
        padding: 10px 55px;

        > div {
            div {
                padding-left: 10px;
            }
        }

        > .section-top-bar-lang {
            padding: 0;
            margin: 0;
            display: flex;

            li {
                list-style: none;
            }
        }

        @media (max-width: 900px) {
            padding: 10px 5px !important;
            .breadcrumbs {
                display: none;
            }
        }
    }

    &.rolled-down-menu {
        margin-left: $menu-rolled-down-width;
    }

    &.rolled-up-menu {
        margin-left: $menu-rolled-up-width;
    }

    &.collapsed-menu {
        margin-top: $nav-item-height;

        .rs-table-affix-header {
            top: $nav-item-height !important;
        }
    }

    &.full-screen-menu {
    }

    &.container-animation-from-rolled-down-to-rolled-up {
        animation: key-container-animation-from-rolled-down-to-rolled-up 0.3s ease;

        @keyframes key-container-animation-from-rolled-down-to-rolled-up {
            0% {
                margin-left: 50px;
            }
            100% {
                margin-left: 200px;
            }
        }
    }

    &.container-animation-from-rolled-up-to-rolled-down {
        animation: key-container-animation-from-rolled-up-to-rolled-down 0.3s ease;

        @keyframes key-container-animation-from-rolled-up-to-rolled-down {
            0% {
                margin-left: 200px;
            }
            100% {
                margin-left: 50px;
            }
        }
    }

    @media screen and (max-width: 960px) {
        .main-container {
            padding: 20px 15px;
        }
        .section-top-bar {
            padding: 10px 15px;
        }
    }
}

.form-lang {
    margin-left: auto !important;
}

.ant-btn > svg + span {
    margin-left: 8px;
}

.ant-dropdown-menu-item svg {
    margin-right: 8px;
}

.ant-upload {
    .ant-upload-text, .ant-upload-hint {
        text-align: left;
        padding: 0 20px;
    }
}

.table-wrapper {
    width: 100%;
    max-width: 100%;
}

div.ant-space {
    width: 100%;
}

div.ant-collapse-header {
    .ant-collapse-arrow {
        top: 10px !important;
    }
}

.data-content-wrapper {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px;
}

.data-content-wrapper-descriptions.ant-descriptions.ant-descriptions-small {
    div.ant-descriptions-view {
        table {
            tbody {
                th,
                td {
                    padding: 0;
                }

                tr.ant-descriptions-row:nth-child(2n):not(tr.ant-descriptions-row:last-child) {
                    > td {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}

div.data-descriptions-with-edit-wrapper {
    position: relative;

    button.data-description-btn {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.card-col {
    position: relative;

    .buttons-wrapper {
        position: absolute;
        top: 10px;
        right: 20px;
        max-width: 0;
        opacity: 0;
        transition: all 0.2s ease;
        z-index: 0;
        transform: scale(0);
    }

    &:hover {
        .buttons-wrapper {
            max-width: 1000px;
            transform: scale(1);
            opacity: 1;
            z-index: 2;
        }
    }
}

.btn {
    button[disabled] {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        .svg-inline--fa {
            height: 0.9em;
        }
    }
}

button.ant-btn.btn,
a.btn-icon-link {
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: gray;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    text-shadow: none;

    > * {
        font-size: 13px;
    }

    &:hover,
    &:focus {
        background-color: #282c34;
        color: #ffffff;
    }

    &-remove {
        margin-right: 0 !important;

        &:hover,
        &:focus {
            border-color: #f93822;
            background-color: #f93822;
            color: #fff;
        }
    }
}
span.btn > button {
    border-radius: 50%;
}
button.ant-btn.ant-btn-icon-only.btn-flex-center,
span.btn-flex-center > button[disabled] {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
span.btn-flex-center {
    width: auto;
}

.ant-table-wrapper.virtual-list-table {
    .ant-table-body {
        overflow: scroll !important;

        @media screen and (min-width: 1440px) {
            overflow: hidden !important;
        }
    }
}

div.ant-table {
    .ant-table-cell.ant-table-selection-column {
        padding-left: 16px;
        padding-right: 16px;

        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
            display: flex;
            border-radius: 50%;
            padding: 0;
            width: 16px;
            height: 16px;
        }
    }

    td.bold,
    th.bold {
        font-weight: bold;
    }

    thead {
        th {
            color: rgba(0, 0, 0, 0.75);
            font-weight: 600;
        }

        th.ant-table-cell.ant-table-selection-column {
            .ant-table-selection {
                display: block;
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner,
                .ant-checkbox-inner::after {
                    transition: none;
                }
            }

            .ant-checkbox-indeterminate .ant-checkbox-inner {
                overflow: hidden;
                border-color: #f93822;

                &::after {
                    z-index: 1;
                    width: 25px;
                    height: 25px;
                    background-color: #f93822;
                    border-radius: 50%;
                    transform: translate(-50%, -50%) scale(1);
                    transition: none;
                }
            }

            .ant-checkbox-indeterminate {
                position: relative;
                z-index: 2;

                &:after {
                    z-index: 2;
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 50%;
                    width: 8px;
                    height: 2px;
                    background-color: #fff;
                    transform: translate(-46%, -51%);
                }
            }
        }
    }

    tbody.ant-table-tbody {
        > tr {
            > td {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

tr.ant-table-row {
    button.ant-btn.btn {
        border-color: transparent;
        box-shadow: none;

        &:hover,
        &:focus {
            border-color: #282c34;
        }
    }

    button.ant-btn.btn.btn-remove {
        &:hover,
        &:focus {
            border-color: #f93822;
        }
    }

    &:nth-child(2n + 1) {
        background: #fafafa;
    }
}
.ant-table-tbody > tr.ant-table-row:hover > td:not(td.logger-changes-table-cell) {
    background: #f3f3f3;
}

h3.ant-typography {
    padding-left: 10px;
    border-left: 3px solid #282c34;
    margin-bottom: 25px;
}

.ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-checkbox {
        top: 0;

        * {
            width: 16px;
            height: 16px;
        }
    }
}

.ant-checkbox-inner::after {
    width: 6px;
    height: 9px;
}

.large.ant-tooltip-disabled-compatible-wrapper {
    display: block !important;
    .ant-checkbox-wrapper {
        * {
            width: 25px;
            height: 25px;
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(1) translate(-4.5px, -8px);
        }

        .ant-checkbox-inner::after {
            width: 8px;
            height: 14px;
        }
    }
}

.ant-checkbox-wrapper.large {
    * {
        width: 25px;
        height: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        transform: rotate(45deg) scale(1) translate(-4.5px, -8px);
    }

    .ant-checkbox-inner::after {
        width: 8px;
        height: 14px;
    }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:not(.orders-edit-form-checkbox) {
    margin: 0 !important;
    padding: 0 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #f93822;
}

.ant-checkbox.ant-checkbox-disabled {
    .ant-checkbox-inner {
        background: #f5f5f5;
    }
}

.ant-checkbox-inner {
    border-radius: 50% !important;
}

.ant-checkbox-checked {
    &:after {
        border: none;
    }

    .ant-checkbox-inner {
        background-color: #f93822;
        border-color: #f93822;
    }
}

.ant-checkbox-disabled {
    .ant-checkbox-inner {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
    }
}

.ant-card.card {
    background-color: #f1f1f1;
    border-radius: 10px;
    height: 100%;

    .ant-card-head {
        border: none;
        padding: 0 20px;

        span.ant-typography-ellipsis {
            max-width: 90%;
        }

        .ant-card-extra,
        .ant-card-head-title {
            padding: 10px 0 5px 0;
        }

        .ant-card-head-title {
            font-weight: 400;
            font-size: 1.3rem;
        }
    }

    .ant-card-body {
        padding: 15px 20px 15px 20px;
    }

    .ant-card-head + .ant-card-body {
        padding-top: 0;
    }
}

.ant-card.card,
.card-col {
    button.ant-btn[disabled] {
        border-radius: 50%;
    }

    button.card-btn.ant-btn {
        //color: rgba(#808080, 0.5) !important;
        border: 1px solid rgba(#808080, 0.5) !important;

        &:hover,
        &:focus {
            color: #fff;
            border: 1px solid #282c34 !important;
        }
    }
    button.card-btn.ant-btn.btn-remove {
        margin-right: 0 !important;

        &:hover,
        &:focus {
            color: #fff;
            border-color: #f93822 !important;
        }
    }
}

.ant-card.card.ant-card-hoverable {
    &:focus-within {
        border-color: transparent;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
            0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
}

.ant-card.card.card-transparent {
    background-color: transparent;
}

.ant-tag.has-agreement-tag {
    display: flex;
    justify-content: center;
    color: #282c34;
    width: 22px;
    border-radius: 50%;
}

.upload-wrapper {
    width: 100%;

    span {
        width: 100%;
        height: 100%;

        .ant-upload.ant-upload-drag {
            padding: 30px;
        }
    }
}

.link {
    color: #282c34;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: #282c34;
        text-decoration: underline;
    }
}

.ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
}

.ant-input-number {
    width: 100%;
}

.ant-page-header-content {
    padding-top: 20px;
}

.ant-select-selection-item {
    background: none !important;
}

button.ant-btn.table-page-button {
    margin-bottom: 20px;
}

@media screen and (min-width: 520px) {
    button.ant-btn.table-page-button {
        margin-bottom: 0;
    }
}

.color-danger {
    background-color: #f93822 !important;
}

a.btn-icon-link {
    padding: 0;
}

.modal-without-borders {
    .ant-modal-content {
        padding: 0;
    }
    .ant-modal-footer {
        display: none;
    }
}

.pre {
    white-space: pre;
}

.v-top {
    vertical-align: top;
}

.ant-tabs {
    overflow: initial !important;
}

.ant-modal-header {
    margin-bottom: 25px !important;
}