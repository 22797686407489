canvas.progress-bar {
    width: 100%;
    height: 100%;
    max-height: 400px;
    // position: fixed;
    // left: 0;
    // top: 0;
    // z-index: -1;
}

.progress-bar-wrapper {
    max-height: 200px;
}
