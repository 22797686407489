.violation-list {
  width: 400px;
  max-height: 90%;
  top: 30px;
  right: 0;
  background-color: #fffbe6;
  position: fixed;
  border: 1px solid #ffe58f;
  z-index: 100000;
  overflow-y: scroll;

  .violation-list-header {

    padding: 10px 15px;
    color: #d48806;
    justify-content: space-between;
    display: flex;

    .violation-list-header-actions {
      display: inline-flex;
      align-items: center;
      .violation-list-header-action {
        padding: 0 5px;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
          background-color: #d48806;
          color: #ffffff;
        }
      }
    }

    .violation-list-header-title {
      font-weight: bold;
    }

  }

  .violation-list-body {
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          color: #d48806;
        }
      }
      .ant-table-cell {
        background: #fffbe6 !important;
        border-bottom: 1px solid #ffe58f;
        padding: 5px 15px;
        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }
}

.violation-list-show-button {
  position: fixed;
  transform: rotate(270deg);
  top: 65px;
  right: -52px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  z-index: 100000;
  padding: 10px 30px;
  color: #d48806;
  font-weight: bold;
  cursor: pointer;
}