
.cart-notification {
  .ant-notification-notice-with-icon {
    .ant-notification-notice-description {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.cart-upload.upload-wrapper {
  .ant-upload.ant-upload-drag {
    p.ant-upload-text.cart-upload-text {
      margin: 0 auto 10px;
    }

    p.ant-upload-text.cart-upload-text.cart-upload-info {
      max-width: 510px;
      text-align: center;
      margin: 0 auto;
    }
  }
}