.inventory-task-modal {
  .inventory-task-modal-rows {
    >.ant-row {
      &:nth-child(2n-1) {
        background-color: #ebebeb;
      }
      >.ant-col {
        padding: 10px 15px;
        &:first-child {
          padding-top: 15px;
        }
        >.ant-row {
          margin: 0;
        }
      }
    }
  }
}