.ant-tag.order-status {
  margin: 0;
  //text-transform: uppercase;

  &-large {
    display: flex;
    width: auto;
    height: auto;
    padding: 10px;
    margin-left: auto;
    font-size: 1.1rem;
  }
}
