.filter-table-btn {
  margin-left: auto;
}
button.ant-btn.ant-btn-icon-only.table-columns-button {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.filtered-table-buttons {
  display: flex;
  flex-direction: column;
  .additional-buttons, .filter-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    button {
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 768px) {
  .filtered-table-buttons {
    flex-direction: row;
    .additional-buttons, .filter-buttons {
      flex-direction: row;
      button {
        // margin-bottom: 0;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

