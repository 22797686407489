.ant-checkbox-wrapper.group-access-checkbox {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-4.5px, -8px);
  }

  .ant-checkbox-inner::after {
    width: 8px;
    height: 14px;
  }

  .ant-checkbox {
    width: 25px;
    height: 25px;
    > * {
      width: 25px;
      height: 25px;
    }
  }
}
