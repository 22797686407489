ul.children-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        height: 32px;
        margin-bottom: 24px;

        a.child-link {
            flex-grow: 1;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            padding: 1px 0 1px 8px;
            line-height: 28px;
        }
        button {
            margin-left: 5px;
        }
    }
}
