.table-footer-descriptions {

  .ant-descriptions-item-container {
    * {
      width: 50%;
      justify-content: flex-end;
    }

    .ant-descriptions-item-content {
      width: 50%;
    }
  }

  .ant-descriptions-item-label {
    width: 50%;
  }
}
