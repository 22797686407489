.property-code {
  cursor: pointer;
  border-bottom: 1px dotted #000000;
}

.property-code-popover {
  width: 255px;
  .property-code-popover-title {
    text-align: center;
    padding: 8px;
    font-weight: bold;
    font-size: 20px;
  }
  .property-code-popover-description {
    text-align: center;
  }
}