.wiki-permissions {
  .wiki-permission-system {
    background-color: #f2f2f2;
    padding: 3px 14px;
    margin-bottom: 10px;
  }
  .wiki-permission {

    position: relative;

    .wiki-permission-actions {
      display: none;
    }

    &:hover {
      .wiki-permission-actions {
        display: flex;
        content: "";
        position: absolute;
        width: calc(100% - 10px);
        height: 100%;
        top: 0;
        left: 0;
        background-color: #f6f6f6;
        z-index: 10;
        opacity: 0.9;
        margin: 0 5px;
      }
    }

    .wiki-permission-branch {
      background-color: #939393;
      color: #ffffff;
      text-align: center;
    }

    .wiki-permission-icon {
      $allowColor: #4FD596;
      $disallowColor: #d54f4f;
      width: 30px;
      height: 30px;
      color: #ffffff;
      text-align: center;
      padding-top: 3px;
      border-radius: 19px;

      &.allow {
        border: 1px solid $allowColor;
        color: $allowColor;
      }

      &.disallow {
        border: 1px solid $disallowColor;
        color: $disallowColor;
        position: relative;

        &:before {
          content: "";
          border-bottom: 1px solid $disallowColor;
          position: absolute;
          width: 100%;
          height: 1px;
          transform: rotate(45deg);
          top: 13px;
          left: 0;
        }
      }
    }
  }
}