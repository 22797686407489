.root-menu-open-lnk {
  position: fixed;
  bottom: 50px;
  right: -15px;
  background-color: #ffa39e;
  transform: rotate(270deg);
  padding: 10px 20px;
  border-radius: 5px;
  color: #cf1322;
  cursor: pointer;
  z-index: 100000;
  font-weight: bold;
}

.root-menu {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 100000;
  background-color: #ffa39e;
  padding: 10px 0;
  border-radius: 5px;
  color: #cf1322;
  border: 1px solid #cf1322;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 5px 15px;
      position: relative;
      a {
        color: #cf1322;
        width: 100%;
        display: block;
      }
      &:hover {
        background-color: #ffe58f;
        cursor: pointer;
      }
      .highlighted {
        font-style: italic;
        color: #571616;
      }
    }
  }
  .root-sub-menu {
    position: absolute;
    top: -10px;
    right: 190px;
    z-index: 100000;
    background-color: #ffa39e;
    padding: 10px;
    border: 1px solid #cf1322;
    border-radius: 5px;
  }
}