.markdown-viewer {
  img {
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 45px;
  }

  h3 {
    font-size: 19px !important;
    margin-bottom: 5px !important;
    margin-top: 30px !important;
  }

  .ant-alert {
    margin: 10px 0 20px 0;
  }

  table {
    border: none;
    margin-bottom: 20px;
    tr {
      &:nth-child(even) {
        background: #F8F8F8;
      }
      th {
        font-weight: bold;
        text-align: left;
        padding: 10px 25px;
        background: #EDEDED;
        font-size: 14px;
      }
      td {
        padding: 10px 25px;
      }
    }
  }
}