.smart-logger-table {
  thead.ant-table-thead {
    th {
      background-color: #f3f3f3;
    }
  }
  tbody.ant-table-tbody {
    tr {
      background-color: #fafafa;
    }
    tr.ant-table-expanded-row {
      td:not(.logger-changes-table-cell) {
        background-color: #fff;
        //> div {
        //}
      }
    }

  }
}