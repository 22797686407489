.route-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  .route-buttons-button {
    display: inline-block;
  }
  @media (max-width: 900px) {
    .route-buttons-button {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}