.ui-input-card-list-cart {
  cursor: pointer;
  padding: 25px 10px 50px 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
  color: #ffffff;
  position: relative;
  background-color: var(--bg-color);
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: #ffffff;
  }
  &.selected {
    &:before {
      opacity: 0;
    }
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    color: #ffffff;
  }
  .hint {
    position: absolute;
    font-weight: normal;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 4px 4px;
    &:before {
      background-color: #000000;
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
  }
  .selectedIcon {
    padding-left: 10px;
    display: inline-block;
  }
}