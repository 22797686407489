.login {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.6);
    background-size: cover;

    img {
      width: 80%;
      height: 100%;
      display: block;
      margin: 0 auto 50px auto;
    }
  }
  &-block {
    width: 100%;
    // max-width: 380px;
  }
  &-row {
    z-index: 10;
  }
  &-form {
    max-width: 350px;
  }

  &-form-items {
    padding: 20px;
    background-color: #fff;
  }
}
