.inventory-task-review {
  .ant-page-header-footer {
    margin: 0 0 10px 0;
  }
  .ant-form {
    //.ant-form-item-label label {
    //  font-size: 16px;
    //  font-weight: normal;
    //  border-bottom: 1px solid #dadada;
    //
    //  &:before {
    //    display: none !important;
    //  }
    //}

    button {
      height: 80px;
      font-size: 20px;
      background-color: #2b60bc;
      margin-top: 5px;
    }
  }
}