.ui-input-func-btn {
  cursor: pointer;
  padding: 15px 10px;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #f3f3f3;
  color: #000000;
}

.ui-input-field {
  height: 84px;
  text-align: center;
  font-size: 35px !important;
}
