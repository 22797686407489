.logger-collapse.ant-collapse-borderless {
    background-color: transparent !important;

    .ant-collapse-item.logger-collapse-panel {
        border: none;
    }
    > .ant-collapse-item.logger-collapse-panel {
        > div.ant-collapse-header {
            background-color: rgba(0, 0, 0, 0.05);
        }
        .ant-collapse-content {
            background: transparent;
        }
    }
    .ant-collapse-content-box {
        border-top: 2px solid #fafafa;
        padding: 0 !important;
    }

    .ant-space-item:first-child {
        margin-bottom: 0 !important;
    }

    // .ant-collapse-arrow {
    //     top: 9px !important;
    //
}
