.filter-popup {
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    top: 100%;
    // bottom: 0px;
    transform: translateY(2px);
    background-color: #fff;
    z-index: 2;
    margin-left: 0px;
    padding: 5px;
    //overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .filterKeySelect {
        width: 100%;
        //margin-right: 5px !important;
    }

    .filterDatePicker,
    .filterValueSelector {
        width: 100%;
        //margin-right: 0px !important;
    }

    .filterValueWithQueryTypeContainer {
        width: 100%;
        //margin-right: 0px !important;
    }

    .filterCloseButton,
    .filterSubmitButton {
        width: 32px;
        //margin-right: 0px !important;
        //margin-left: 5px !important;
    }

    .filterKeySelect,
    .filterDatePicker,
    .filterValueSelector,
    .filterValueWithQueryTypeContainer {
        margin-bottom: 6px !important;
    }

    .filterCloseButton,
    .filterSubmitButton {
        width: calc(50% - 4px);
        margin-right: 6px !important;
    }

    .filterCloseButton {
        margin-right: 0px !important;
    }

    @media (min-width: 576px) {
        width: auto;

        .filterKeySelect,
        .filterDatePicker,
        .filterValueSelector,
        .filterValueWithQueryTypeContainer {
            margin-bottom: 0px !important;
        }

        .filterKeySelect {
            width: 120px;
            margin-right: 5px !important;
        }

        .filterDatePicker,
        .filterValueSelector {
            width: 195px;
            margin-right: 0px !important;
        }

        .filterValueWithQueryTypeContainer {
            width: 310px;
            margin-right: 0px !important;
        }

        .filterCloseButton,
        .filterSubmitButton {
            width: 32px;
            margin-right: 0px !important;
            margin-left: 5px !important;
        }
    }

    .ant-form-item-with-help {
        margin-bottom: 0px !important;
    }

    form {
        //width: 100%;
    }
}

@media screen and (max-width: 610px) {
    .ant-picker-panels {
        flex-direction: column;
    }
}
