div.ant-space.invoice-data-header-buttons {
  width: auto;
  margin-bottom: 20px !important;
}

@media screen and (min-width: 670px){
  div.ant-space.invoice-data-header-buttons {
    margin-bottom: 0 !important;
  }
}
