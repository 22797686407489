.ant-checkbox-wrapper.orders-edit-form-checkbox, .ant-checkbox-wrapper-disabled {
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  background-color: #f1f1f1;
  padding: 7px 10px;
  color: #545f64;
  margin: 0 5px 5px 0;

  span.orders-edit-form-checkbox-warehouse {
    order: 1;
  }

  .ant-checkbox {
    order: 2;
  }

  &:focus .ant-checkbox-inner,
  &:hover .ant-checkbox-inner {
    border-color: #f93822;
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #f93822;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &:after {
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        width: 5.71428571px;
        height: 9.14285714px;
      }
    }
  }
  .ant-checkbox-inner {
    border-radius: 50%;
  }

  .ant-checkbox-checked {
    &:after {
      border: none
    }

    .ant-checkbox-inner {
      background-color: #f93822;
      border-color: #f93822;
    }
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: #f5f5f5;
      border-color: #d9d9d9 !important;
    }
  }
}
.ant-checkbox-wrapper.orders-edit-form-checkbox + .ant-checkbox-wrapper.orders-edit-form-checkbox {
  margin-left: 0;
}

table.edit-form-table {
  td {
    padding: 10px 15px;
  }
  td.label {
    text-align: right;
  }
}