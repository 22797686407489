.smart-logger-changes-table.ant-table-wrapper {
  width: 100%;
  max-width: 800px;
  .ant-table-container {
    &:before, &:after {
      content: none;
    }
  }
  th.ant-table-cell, td.ant-table-cell {
    padding: 8px 12px;
    background-color: #fff;
  }

  tbody {
    tr:hover {
      td {
        background-color: #fff;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
    td {
      background-color: #fff;
    }
    .old-value-col, .new-value-col {
      vertical-align: baseline;
    }
    .old-value-col {
      background-color: #FBE9EB !important;
    }


    .new-value-col {
      background-color: #ECFCF0 !important;
    }


    tr.update-row:hover {
      .old-value-col {
        background-color: #FBE9EB !important;
      }


      .new-value-col {
        background-color: #ECFCF0 !important;
      }
    }
  }


}