.website-tree {
  .website-tree-item {
    justify-content: space-between;
    display: flex;
    .website-tree-item-title {
      padding-right: 10px;
    }
  }
  .ant-tree-treenode {
    width: 100%;
    .ant-tree-node-content-wrapper {
      width: 100%;
    }
  }
}