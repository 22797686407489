div.ant-space.order-data-header-buttons {
  width: auto;
  margin-bottom: 10px !important;
}

@media screen and (min-width: 768px){
  div.ant-space.order-data-header-buttons {
    margin-bottom: 0 !important;
  }
}
