.agreement-row {
  position: relative;
  padding-right: 60px;
  .agreement-buttons-col {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    max-width: 0;
    transform: scale(0);
    transition: all .2s ease;
  }
  &:hover {
    .agreement-buttons-col {
      opacity: 1;
      max-width: 1000px;
      transform: scale(1);
    }
  }
}

.ant-page-header-heading-title {
  overflow: visible !important;
  white-space: normal !important;
}
.ant-page-header-heading-left {
  overflow: visible !important;
}
.agent-data-comment-wrapper {
  .ant-descriptions-item-label {
    padding-top: 3px;
  }
}