.planner-task-view {
  .ant-divider {
    margin: 10px 0 10px 0;
  }
  .planner-task-view-title {
    font-weight: bold;
    font-size: 16px;
    a {
      color: #1b1b1b;
      &:hover {
        color: #808080;
      }
    }
  }
  .planner-task-view-tags {
    margin: 0 -5px;
    .ant-tag {
      margin: 5px 5px;
    }
  }
  .planner-task-view-details {
    color: #717171;
    font-size: 12px;
  }
}